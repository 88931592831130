/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.12.1/dist/jquery.min.js
 * - /npm/jquery-validation@1.15.1/dist/jquery.validate.min.js
 * - /npm/slick-carousel@1.5.9/slick/slick.min.js
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /npm/picturefill@3.0.2/dist/picturefill.min.js
 * - /npm/moment@2.17.1/moment.min.js
 * - /npm/eonasdan-bootstrap-datetimepicker@4.17.47/src/js/bootstrap-datetimepicker.min.js
 * - /npm/typeahead.js@0.11.1/dist/typeahead.bundle.min.js
 * - /npm/handlebars@4.0.5/dist/handlebars.min.js
 * - /npm/jquery.maskedinput@1.4.1/src/jquery.maskedinput.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
